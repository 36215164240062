.TaskConfigForm {
  border: 1px solid darkslateblue;
  ul {
    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    max-height: 8em;
    overflow-y: auto;
  }
}

.Actions {
  display: flex;
  flex-direction: row;
}

.Holder {
  margin: 5px;
  .Breakdown {
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 1em;
  }
}
