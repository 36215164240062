.SingleAgentBlock {
  width: 90%;
  height: 70px;
  margin: 5px;
  border: 1px solid darkslateblue;
  display: flex;
  flex-direction: column;
}

.ScalingDown {
  @extend .SingleAgentBlock;
  border: 3px solid darkseagreen !important;
}