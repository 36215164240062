.App {
  display: flex;
  flex-direction: row;
  min-width: 100vw;
  min-height: 100vh;
  justify-content: space-between;
  padding: 8px;

  .Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 25vw;
    min-height: 1vh;
  }
}