.ValuesBlock {
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  width: 100%;
}

.Value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ValueLabel {

  }

  .ValueValue {

  }
}

